import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import Modal from "components/modal/Modal";
import { RetrievePalResponseDto, useRetrievePalContentQuery } from "../dashboardService";
import { useSelector } from "react-redux";
import { getCurrentUserId, getLoanId } from "features/authSlice";
import Overlay from "components/ui/loading-spinners/Overlay/Overlay";
import { Button } from "components/ui/button/Button";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import "./preApprovalPreview.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { shareFiles } from "utilities/files";
import { PalDocumentDefaultName } from "utilities/Constant";

interface PreviewProps {
  readonly onClose?: () => void;
  readonly palDocument?: RetrievePalResponseDto;
}
export default function PreApprovalPreview({ onClose, palDocument }: PreviewProps) {
  const userId = useSelector(getCurrentUserId);
  const loanId = useSelector(getLoanId);
  const shouldSkipQuery = !!palDocument;

  const {
    data: retrievePalData,
    isLoading: isRetreivePalLoading,
    isSuccess: isRetreivePalSuccess,
  } = useRetrievePalContentQuery(
    { userId, loanId },
    {
      skip: shouldSkipQuery,
    }
  );

  if (isRetreivePalLoading) {
    return <Overlay />;
  }

  const getPathUrl = (document?: RetrievePalResponseDto) => {
    if (document) {
      const blob = new Blob([Uint8Array.from(window.atob(document.documentContent?.fileContents ?? ""), (c) => c.charCodeAt(0))], {
        type: "application/pdf",
      });
      const url = window.URL.createObjectURL(blob);
      return url;
    } else {
      return "";
    }
  };

  let currentDocument: RetrievePalResponseDto | undefined;

  if (palDocument) {
    currentDocument = palDocument;
  } else if (isRetreivePalSuccess) {
    currentDocument = retrievePalData[0];
  }

  const handleSharePAL = async () => {
    try {
      const blob = new Blob([Uint8Array.from(window.atob(currentDocument?.documentContent?.fileContents ?? ""), (c) => c.charCodeAt(0))], {
        type: "application/pdf",
      });
      const file = new File([blob], currentDocument?.documentContent?.name ?? PalDocumentDefaultName, { type: "application/pdf" });
      await shareFiles({
        text: currentDocument?.documentContent?.name ?? PalDocumentDefaultName,
        files: [file],
        title: currentDocument?.documentContent?.name ?? PalDocumentDefaultName,
      } as ShareData);
    } catch (error) {
      console.error("Error preparing file for sharing:", error);
    }
  };

  const docs = [{ uri: getPathUrl(currentDocument), fileName: currentDocument?.documentContent?.name ?? "" }];

  return (
    <div className="preApproval">
      <Modal
        defaultHeader
        defaultHeaderIcon={solid("file-lines")}
        defaultHeaderName="Pre-Approval Letter"
        defaultHandleHide={onClose}
        defaultBody={false}
        footerContentPosition="right"
        customBody={
          <>
            {getPathUrl(currentDocument) ? (
              <>
                <div className="topSplitter" />
                <DocViewer documents={docs} pluginRenderers={DocViewerRenderers} />
              </>
            ) : (
              <h1>Oops! There's a problem viewing this document.</h1>
            )}
          </>
        }
        footer={
          <div className="previewFooter">
            <a href={getPathUrl(currentDocument)} download={currentDocument?.documentContent?.name} target="_blank" rel="noreferrer">
              <Button title={"Download"} type={"round"} variant={"outline-primary"} />
            </a>
            <Button type={"round"} variant={"primary"} onClick={handleSharePAL} className="sendPAL">
              <>
                <FontAwesomeIcon className="sendIcon" icon={solid("up-from-bracket")} /> Send Pre-Approval Letter
              </>
            </Button>
          </div>
        }
      />
    </div>
  );
}
